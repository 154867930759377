import { PageProps, graphql } from 'gatsby'
import React, { FC } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import {
  ExternalTarget,
  PageRichText,
  SEO,
  Section,
  ServiceList,
} from '@wunderdogsw/finbb-dms-theme'

import { PageContextProps } from './page'
import Layout from '../components/layout/layout'
import Operator from '../components/operator/operator'
import { Contact, ContactData } from '@wunderdogsw/finbb-dms-theme'
import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text'
import OperatorHero from '../components/operator/operator-hero'

// TODO: Move to locale file
const ABOUT = 'About'
const AFFILIATE_PARTNERS = 'Affiliate partners'
const CONTACT_US = 'Contact us'
const REFERENCES = 'References'
const SERVICES = 'Services'

type DataProps = {
  contentfulOperator: {
    contacts: ContactData[]
    coverImage?: {
      file: {
        contentType: string
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    description: {
      raw: string
      references: any[]
    }
    displayName: string
    fields: {
      route: string
    }
    logo?: {
      file: {
        url: string
      }
      gatsbyImageData: IGatsbyImageData
    }
    metaDescription?: { metaDescription: string }
    partnerLinks?: ExternalTarget[]
    referenceLinks?: ExternalTarget[]
    services: {
      id: string
      description: RenderRichTextData<ContentfulRichTextGatsbyReference>
      displayName: string
      referenceLinks?: ExternalTarget[]
      website: string
    }[]
    website: string
  }
}

const OperatorPage: FC<PageProps<DataProps, PageContextProps>> = ({ data }) => {
  const { contentfulOperator: operator } = data
  const {
    contacts,
    coverImage,
    description,
    displayName,
    logo,
    metaDescription,
    partnerLinks,
    referenceLinks,
    services,
  } = operator
  const { route } = operator.fields

  const serviceData = services.map((service) => ({
    childrenContentfulService: [service],
    id: service.id,
  }))

  return (
    <Layout>
      <SEO
        title={displayName}
        description={metaDescription && metaDescription.metaDescription}
        image={coverImage && coverImage.file.url}
        path={route}
      />
      <OperatorHero
        name={displayName}
        metaDescription={metaDescription && metaDescription.metaDescription}
        logo={logo}
        image={coverImage}
      />
      <Section.Body>
        <div className="prose prose-h1:font-bold prose-h2:font-bold prose-h3:font-bold prose-h4:font-bold max-w-3xl mx-auto overflow-hidden">
          {services && (
            <>
              <h2 id="services">{SERVICES}</h2>
              <ServiceList data={serviceData} headers={false} />
            </>
          )}
          {referenceLinks && (
            <Operator.LinkGroup links={referenceLinks} title={REFERENCES} />
          )}

          <h2 id="contact">{CONTACT_US}</h2>
          <ul className="list-none mx-0 px-0 space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
            {contacts.map((contact) => (
              <Contact
                key={contact.id}
                data={contact}
                size="small"
                inverse={false}
              />
            ))}
          </ul>
          <h2 id="about">
            {ABOUT} {displayName}
          </h2>
          <PageRichText>{description}</PageRichText>
          {partnerLinks && (
            <Operator.LinkGroup
              links={partnerLinks}
              title={AFFILIATE_PARTNERS}
            />
          )}
        </div>
        {logo && (
          <div className="w-64 mx-auto my-16">
            <GatsbyImage
              image={logo.gatsbyImageData}
              imgStyle={{
                objectFit: 'contain',
                objectPosition: 'center',
              }}
              className="h-64"
            />
          </div>
        )}
      </Section.Body>
    </Layout>
  )
}

export default OperatorPage

export const query = graphql`
  query ($slug: String!, $locale: String!) {
    contentfulOperator(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contacts {
        displayName
        email
        id
        phone
        responsibility
        title
      }
      contacts {
        ...Contact
      }
      coverImage {
        file {
          contentType
          url
        }
        gatsbyImageData(width: 2560)
      }
      description {
        raw
        references {
          __typename
          ... on Node {
            ...RichTextReferences
          }
        }
      }
      displayName
      fields {
        route
      }
      logo {
        file {
          url
        }
        gatsbyImageData(width: 640)
      }
      metaDescription {
        metaDescription
      }
      partnerLinks {
        ... on Node {
          ... on ContentfulLink {
            ...Link
          }
        }
      }
      referenceLinks {
        ... on Node {
          ... on ContentfulLink {
            ...Link
          }
        }
      }
      services {
        id
        description {
          raw
          references {
            __typename
            ... on Node {
              ... on ContentfulSuperscriptInline {
                ...SuperscriptInline
              }
            }
          }
        }
        displayName
        referenceLinks {
          ... on Node {
            ... on ContentfulLink {
              ...Link
            }
          }
        }
        website
      }
      slug
      website
    }
  }
`
