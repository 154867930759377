import React, { ReactNode } from 'react'
import OperatorLinkGroup from './operator-link-group'

type OperatorProps = {
  children: ReactNode
}

const Operator = ({ children }: OperatorProps): JSX.Element => {
  return <div>{children}</div>
}

export default Object.assign(Operator, { LinkGroup: OperatorLinkGroup })
