import { ExternalLinkIcon } from '@heroicons/react/solid'
import {
  ExternalTarget,
  InternalTarget,
  Link,
} from '@wunderdogsw/finbb-dms-theme'
import React, { ReactNode } from 'react'

type OperatorLinkProps = {
  children: ReactNode
  to: ExternalTarget | InternalTarget | string
}

const OperatorLink = ({ children, to }: OperatorLinkProps): JSX.Element => {
  const handleClick = () => {
    const hrefFromObject = (obj: ExternalTarget | InternalTarget) =>
      'url' in obj ? obj.url : obj.fields?.route

    const href = typeof to === 'string' ? to : hrefFromObject(to)

    window['dataLayer'] &&
      window['dataLayer'].push({ event: 'operator_page_link_click', href })
  }

  return (
    <Link
      to={to}
      target="_blank"
      onClick={handleClick}
      className="flex items-center space-x-1"
    >
      <span>{children}</span>
      <ExternalLinkIcon className="shrink-0 h-4 w-4" />
    </Link>
  )
}

export default OperatorLink
