import React from 'react'
import { ExternalTarget } from '@wunderdogsw/finbb-dms-theme'
import { FC } from 'react'
import OperatorLink from './operator-link'

type OperatorLinkGroupProps = {
  links: (ExternalTarget | string)[]
  title: string
}

const OperatorLinkGroup: FC<OperatorLinkGroupProps> = ({ links, title }) => {
  return (
    <>
      <h2>{title}</h2>
      <p className="">
        {links.map((link) => {
          const id = typeof link === 'string' ? link : link.id
          const caption = typeof link === 'string' ? link : link.text

          return (
            <OperatorLink key={id} to={link}>
              {caption}
            </OperatorLink>
          )
        })}
      </p>
    </>
  )
}

export default OperatorLinkGroup
