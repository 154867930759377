import { BackgroundImage, Hero } from '@wunderdogsw/finbb-dms-theme'
import React, { ReactNode } from 'react'
import HeroBanner from '../hero/hero-banner'
import { IGatsbyImageData } from 'gatsby-plugin-image'

// TODO: Move to locale file
const BANNER_TEXT = 'Selected Ecosystem Service Partner'
const CONTACT_US = 'Contact us'

type OperatorHeroProps = {
  image: {
    file: {
      contentType: string
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  logo?: {
    file: {
      url: string
    }
    gatsbyImageData: IGatsbyImageData
  }
  metaDescription?: string
  name: string
}

const OperatorHero = ({
  image,
  logo,
  metaDescription,
  name,
}: OperatorHeroProps): JSX.Element => {
  return (
    <div className="relative">
      <BackgroundImage gatsbyImageData={image.gatsbyImageData} overlay={true} />
      <HeroBanner image={logo} text={BANNER_TEXT} />
      <div className="pt-16 pb-16">
        <Hero>
          <div className="text-center">
            <Hero.Title landing={true} inverse shadow>
              {name}
            </Hero.Title>
            {metaDescription && (
              <Hero.Lead inverse shadow>
                {metaDescription}
              </Hero.Lead>
            )}
          </div>
          <Hero.CallToActionList
            landing={true}
            primary={{ caption: CONTACT_US, target: '#contact' }}
          />
        </Hero>
      </div>
    </div>
  )
}

export default OperatorHero
